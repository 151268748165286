<template>
  <div class="details-page" ref="page" @click="wxShow = false">
    <!-- <top-box :option="2"></top-box> -->
    <div class="bannar">
      <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
      <img :src="bannerSrc" v-if="bannerType" />
      <video
        v-else
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <div class="overlay"></div>
      <div class="bannar-info">
        <!-- <div class="bannar-title">活动详情</div>
                <div class="bannar-detail">{{bannerTitle}}</div> -->
        <div class="banner-content">
          <div class="bannar-title">活动详情</div>
          <div class="bannar-detail">{{ bannerTitle }}</div>
        </div>
        <img class="banner-img" src="../../image/top/banner4.png" />
      </div>
    </div>
    <main>
      <div class="main-title">{{ info.name }}</div>
      <div class="main-info">
        <span>发布时间：{{ info.time }}</span>
        <span>来源：成都天府国际赛道</span>
        <!-- <span>阅读量：100</span> -->
      </div>
      <!-- <div style="width:100%;height:1px;background-color:#DEDEDE;"></div> -->
      <div class="detials-border"></div>
      <div class="main-activity-info">
        <div class="activity-info-left">
          <p>活动人群：{{ info.activityObject }}</p>
          <p>报名时间：{{ info.enrollBeginTime }}至{{ info.enrollEndTime }}</p>
          <p>活动时间：{{ info.beginTime }}至{{ info.endTime }}</p>
          <p>活动人数：{{ info.totalNum }}人</p>
          <p>提供服务：{{ info.provideServices || "暂无" }}</p>
        </div>
        <div class="activity-info-right">
          <p>
            报名费用： <span>￥{{ info.price }}</span>
          </p>
          <!-- <span v-if="info.status == 1" @click="activityDetails">立即报名</span> -->
          <span @click="activityDetails" v-if="info.enrollStatus == 1"
            >立即报名</span
          >
          <span
            v-else-if="info.enrollStatus == 0"
            style="backgroundcolor: #dddddd; color: #999"
            >即将开始</span
          >
          <span v-else style="backgroundcolor: #dddddd; color: #999"
            >报名结束</span
          >
        </div>
      </div>
      <div
        class="activity-details"
        v-html="info.details"
        style="textalign: center"
      ></div>
      <div
        style="
          width: 100%;
          height: 1px;
          background-color: #ff6720;
          margin-top: 40px;
        "
      ></div>
      <div class="next-activity">
        <div class="left" v-if="nextInfo">
          <span>下一篇</span>
          <span @click="nextClick">{{ nextInfo.name }}</span>
        </div>
        <div class="left" v-else></div>
        <div class="right" v-if="!_isMobile()">
          <span>分享到：</span>
          <div class="right-wx" @click.stop="shareToWX">
            <img src="../../image/wx.png" alt="" />
            <div class="right-wxShare" v-show="wxShow">
              <div class="wxShare-close" @click.stop="wxShow = false">
                <i class="el-icon-close"></i>
              </div>
              <vueQr
                :margin="0"
                :size="280"
                :whiteMargin="true"
                :logoMargin="3"
                :logoCornerRadius="20"
                :text="wxHref"
              ></vueQr>
              <p>用微信扫码二维码</p>
              <p>分享至好友和朋友圈</p>
            </div>
          </div>
          <img src="../../image/wb.png" alt="" @click="shareToMicroblog" />
        </div>
      </div>
    </main>
    <div class="dialong" ref="dialong" v-if="dialongShow" @click="closeClick">
      <!-- <div class="code">
                <img src="../../image/test.png" alt="">
            </div> -->
      <div class="overlay-box">
        <div class="overlay-title">微信小程序登录</div>
        <div class="overlay-img">
          <img src="@/image/xc.png" style="width: 100%; height: 100%" />
          <!-- <vueQr :margin='10' :size='240' :whiteMargin="true" :logoMargin="3" :logoCornerRadius="20" :text='wxHref'></vueQr> -->
        </div>
        <div class="overlay-app">
          请使用微信扫描二维码登录<br />“天府国际赛道小程序”
        </div>
        <div class="overlay-close" @click.stop="closeClick"></div>
      </div>
    </div>
    <!-- <bottom-box></bottom-box> -->
  </div>
</template>
<script>
import {
  getBanner,
  getActivityDetails,
  getNextActivity,
} from "../../api/homeApi";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      dialongShow: false,
      bannerSrc: "",
      bannerType: 1, //1.图片 0.视频
      bannerTitle: "",
      id: "",
      info: {},
      nextId: "",
      nextInfo: {},
      wxHref: window.location.href,
      // wxHref: 'https://saidao.cdls666.com:8803/activity-details/55',
      wxShow: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getBanner().then(() => {
      let myvideo = document.getElementById("startvideo");
      let bgBox = document.getElementsByClassName("banner-img")[0];
      if (myvideo) {
        myvideo.addEventListener("timeupdate", () => {
          var playTime;
          playTime = Math.floor(myvideo.currentTime);
          if (playTime >= Math.floor(myvideo.duration)) {
            bgBox.style.opacity = 0.6;
            bgBox.style.transition = "";
          } else {
            if (playTime == 0) {
              if (!bgBox.style.transition) {
                bgBox.style.opacity = 0;
                bgBox.style.transition = "4s all";
              }
            }
          }
        });
      }
    });
    this.getInfo();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    async getBanner() {
      await getBanner({
        plate: 2,
      }).then((res) => {
        if (res.code == 0) {
          if (res.infos.length > 0) {
            if (this._isMobile()) {
              this.bannerType = 1;
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].mobileImage)[0].raw
              );
            } else {
              let arr = JSON.parse(res.infos[0].image);
              if (
                [
                  "image/png", // .png
                  "image/jpeg", // .jpg .jpeg
                  "image/x-ms-bmp", // .bmp
                  "image/gif", // .gif
                  "image/tiff", // .tif .tiff
                ].includes(arr[0].type)
              ) {
                this.bannerType = 1;
              } else {
                this.bannerType = 0;
              }
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].image)[0].raw
              );
            }
            this.bannerTitle = res.infos[0].content;
          }
        }
      });
    },
    closeClick() {
      this.$store.commit("common/mutationOverlayStatus", false);
      this.dialongShow = false;
      this.cancelDisMouseWheel();
    },
    activityDetails() {
      this.dialongShow = true;
      this.disabledMouseWheel();
      this.$store.commit("common/mutationOverlayStatus", true);
      var width = document.documentElement.clientWidth;
      let scale = 1;
      var scrollTop = document.documentElement.scrollTop;
      // var scrollTop = document.documentElement.clientHeight
      if (width < 1400) {
        scale = width / 1400;
        scrollTop = scrollTop / scale;
      }
      this.$nextTick(() => {
        var box = document.querySelector(".overlay-box");
        var hei = document.documentElement.clientHeight / scale;
        box.style.top = hei / 4 + "px";
        this.$refs.dialong.style.height = `${hei}px`;
        this.$refs.dialong.style.top = `${scrollTop}px`;
      });
    },
    // 禁止滚动
    disabledMouseWheel() {
      document.addEventListener("DOMMouseScroll", this.scrollFunc, {
        passive: false,
      });
      document.addEventListener("mousewheel", this.scrollFunc, {
        passive: false,
      });
    },
    // 取消禁止滚动
    cancelDisMouseWheel() {
      document.removeEventListener("DOMMouseScroll", this.scrollFunc, {
        passive: false,
      });
      document.removeEventListener("mousewheel", this.scrollFunc, {
        passive: false,
      });
    },
    scrollFunc(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
    getNextActivity() {
      getNextActivity({
        id: this.id,
      }).then((res) => {
        if (res.code == 0) {
          this.nextInfo = res.info;
        }
      });
    },
    nextClick() {
      this.$router.push(`/activity-details/${this.nextInfo.id}`);
      this.id = this.$route.params.id;
      // this.getBanner()
      this.getInfo();
      // this.$router.go(0)
    },
    getInfo() {
      getActivityDetails({
        id: this.id,
      }).then((res) => {
        if (res.code == 0) {
          this.getNextActivity();
          let arr = res.info.endTime.split("-");
          let arr2 = res.info.beginTime.split("-");
          let arr3 = res.info.enrollBeginTime.split("-");
          let arr4 = res.info.enrollEndTime.split("-");
          res.info.endTime = arr[1] + "月-" + arr[2] + "日";
          res.info.beginTime = arr2[1] + "月-" + arr2[2] + "日";
          res.info.enrollBeginTime = arr3[1] + "月-" + arr3[2] + "日";
          res.info.enrollEndTime = arr4[1] + "月-" + arr4[2] + "日";
          res.info.time = res.info.createTime.split(" ")[0];
          this.info = res.info;
        }
      });
    },
    // 分享到微博
    shareToMicroblog() {
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          window.location.href +
          "&title=" +
          "天府国际赛道赛事活动"
      );
    },
    shareToWX() {
      if (this._isMobile()) {
        //    window.open('http://zixuephp.net/inc/qrcode_img.php?url=' + window.location.href)
      } else {
        this.wxShow = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.details-page {
  width: 100%;
  min-height: 100vh;
  background: url("../../image/home/bg.jpg") center center no-repeat;
  .dialong {
    position: absolute;
    height: 28rem;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(41, 41, 41, 0.5);
    z-index: 9999;
    .code {
      height: 3rem;
      width: 3rem;
      background-color: #f1f1f1;
      margin: 0 auto;
      margin-top: 3rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .overlay-box {
      position: absolute;
      width: 100%;
      top: 200px;
      // text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      .overlay-title {
        font-size: 24px;
      }
      .overlay-img {
        height: 240px;
        width: 240px;
        background-color: #fff;
        margin: 20px;
      }
      .overlay-app {
        font-size: 14px;
        width: 240px;
        border-radius: 25px;
        background-color: #ee6a23;
        padding: 5px 0;
        text-align: center;
      }
      .overlay-close {
        height: 30px;
        width: 30px;
        background: url("../../image/close2.png") center center no-repeat;
        background-size: 100%;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
  .bannar {
    width: 100%;
    height: 100%;
    background: #666;
    position: relative;
    img {
      // height: 100%;
      width: 100%;
      vertical-align: middle;
      height: calc((725 / 1920) * 100vw);
      min-height: 500px;
      max-height: 725px;
    }
    .bannar-info {
      position: absolute;
      top: 0%;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      z-index: 9;
      .banner-img {
        height: 100%;
        // width: 1820px;
        position: absolute;
        // padding-right: 100px;
        top: 0;
        left: -480px;
        opacity: 0.63;
      }
      .banner-content {
        position: absolute;
        z-index: 9;
        padding-left: 90px;
        top: 55%;
        text-align: left;
      }
      .bannar-title {
        font-size: 42px;
        color: #fff;
        margin-bottom: 20px;
      }
      .bannar-detail {
        font-size: 28px;
        color: #fff;
        // cursor: pointer;
      }
    }
  }
  main {
    width: 1380px;
    margin: 0 auto;
    padding: 60px 0;
    .main-title {
      font-size: 28px;
      color: #333;
      width: 100%;
      text-align: center;
    }
    .main-info {
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: #999;
      margin: 20px 0;
      span:nth-child(2) {
        // margin: 0 40px;
        margin-left: 40px;
      }
    }
    .main-activity-info {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 40px;
      background-color: #f1f1f1;
      display: flex;
      box-shadow: 1px 1px 10px #ccc;
      .activity-info-left,
      .activity-info-right {
        width: 50%;
      }
      .activity-info-left {
        p {
          font-size: 14px;
          color: #999;
          margin: 0;
          margin-bottom: 20px;
        }
        p:last-child {
          margin: 0;
        }
      }
      .activity-info-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        span:nth-child(2) {
          width: 160px;
          background-color: #ee6a23;
          font-size: 18px;
          color: #fff;
          text-align: center;
          line-height: 56px;
          cursor: pointer;
          margin-left: 110px;
        }
        p {
          color: #999;
          span {
            color: #ee6a23;
            font-size: 24px;
          }
        }
      }
    }
    .info-image {
      width: 100%;
      height: 500px;
      background-color: #999;
      vertical-align: bottom;
    }
    .info-image.one {
      margin-top: 20px;
    }
    .info-image.two {
      margin-bottom: 40px;
    }

    .activity-content {
      width: 100%;
      font-size: 14px;
      color: #666;
      margin: 30px 0;
      line-height: 2;
    }
    .next-activity {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      .left {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
        span {
          margin-right: 20px;
        }
        span:last-child {
          cursor: pointer;
        }
      }
      .right {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666;
        img {
          height: 42px;
          width: 42px;
          margin-left: 28px;
          cursor: pointer;
        }
        .right-wx {
          position: relative;
          .wxShare-close {
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
          }
          .right-wxShare {
            position: absolute;
            top: 50px;
            left: -40px;
            // height: 100px;
            width: 180px;
            background: #fff;
            border: 1px solid #ddd;
            padding: 20px;
            padding-bottom: 0;
            padding-top: 30px;
            img {
              height: 140px;
              width: 140px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
/deep/.activity-details {
  p {
    span {
      color: #666 !important;
    }
    img {
      width: 100% !important;
    }
  }
}
.detials-border {
  border: 1px solid #ff6720;
  margin-bottom: 40px;
}
</style>