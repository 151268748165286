<template>
  <div class="bottom-page">
    <footer>
      <div class="bottom-top">
        <div class="logo" @click="goToClick">
          <img src="../../image/bottom/logo.png" />
          <!-- <img src="../../image/logo.png" /> -->
        </div>
        <div class="bottom-top-right">
          <div class="contact">
            <div class="contact-item">
              <div class="title">联系我们</div>
              <div class="info">名称：{{ info.name }}</div>
              <div class="info" style="margin-bottom: 30px">
                电话：{{ info.phone }}
              </div>
            </div>
            <div class="contact-item">
              <div class="info">地址：{{ info.address }}</div>
              <div class="info" style="margin-bottom: 30px">
                手机：{{ info.mobilePhone }}
              </div>
            </div>
            <!-- <div class="contact-item">
              <div class="info">电话：{{info.phone}}</div>
            </div>
            <div class="contact-item">
              <div class="info">手机：{{info.mobilePhone}}</div>
            </div> -->
            <div class="contact-item">
              <img
                v-if="info.image && info.image.length > 0"
                :src="formatImageUrl(info.image[0].raw)"
              />
              <img v-else />
              <!-- <div class="bottom">官方微信</div> -->
            </div>
          </div>
          <div class="bottom-href">
            <a
              :href="item.addressLink"
              class="href-item"
              target="blank"
              v-for="(item, index) in links"
              :key="index"
            >
              <span>{{ item.name }}</span>
              <img src="../../image/lianjie.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="bottom-info">
        <a href="https://beian.miit.gov.cn/" target="blank" class="info-left">
          <div>备案号：{{ info.icp }}</div>
        </a>
        <div class="info-right">
          <img src="../../image/police.png" alt="" />
          <p>{{ info.network }}</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { getBottomInfo, getBottomLink } from "../../api/homeApi";
export default {
  data() {
    return {
      info: "",
      links: [],
    };
  },
  created() {
    this.getInfo();
    this.getLink();
  },
  methods: {
    getInfo() {
      getBottomInfo().then((res) => {
        if (res.code == 0) {
          this.info = res.info;
          if (res.info.image) {
            res.info.image = JSON.parse(res.info.image);
          }
        }
      });
    },
    getLink() {
      getBottomLink().then((res) => {
        if (res.code == 0) {
          this.links = res.infos;
          res.infos.forEach((item) => {
            if (!item.addressLink.includes("http")) {
              item.addressLink = "http://" + item.addressLink;
            }
          });
        }
      });
    },
    goToClick() {
      this.$router.push("/home");
    },
  },
};
</script>
<style lang="less" scoped>
.bottom-page {
  box-sizing: border-box;
  padding: 0.9rem 0rem;
  // background: #2d2d2d;
  color: #fff;
  background: url("../../image/bottom/bottom-bg.jpg") center center no-repeat;
  background-size: 100% 100%;
}
footer {
  width: 14.2rem;
  margin: 0 auto;
  .bottom-top {
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
    display: flex;
    .bottom-top-right {
      flex: 1;
    }
  }
  .logo {
    width: 1.9rem;
    // height: 0.8rem;
    margin-right: 120px;
    cursor: pointer;
    img {
      width: 100%;
      height: 123px;
    }
    // img:last-child {
    //   height: 50px;
    // }
  }
  .contact {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .contact-item {
      .title {
        font-size: 0.2rem;
        margin-bottom: 0.5rem;
      }
      .info {
        font-size: 0.2rem;
        margin-bottom: 0.2rem;
      }
      .bottom {
        font-size: 0.2rem;
        line-height: 0.2rem;
        text-align: center;
      }
      img {
        width: 1.15rem;
        height: 1.15rem;
        border: 1px solid #fff;
      }
    }
  }
  .bottom-href {
    padding: 0.4rem 0;
    display: flex;
    // justify-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .href-item:nth-child(4n) {
      margin-right: 0;
      margin-bottom: 40px;
    }
    .href-item {
      width: calc(100% / 4 - 1.2rem / 4);
      margin-right: 0.4rem;
      background-color: #fff;
      border-radius: 0.05rem;
      box-sizing: border-box;
      padding: 0.05rem 0.1rem;
      font-size: 0.12rem;
      height: 0.3rem;
      color: #333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 20px;
      }
    }
  }
  .bottom-info {
    display: flex;
    justify-content: center;
    padding-top: 0.24rem;
    width: 100%;
    .info-left,
    .info-right {
      font-size: 0.16rem;
      margin-right: 0.8rem;
      display: flex;
      align-items: center;
      color: #fff;
      img {
        height: 18px;
        margin-right: 5px;
        width: 18px;
      }
    }
    .info-right {
      margin-right: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>